
export default function AppLoading(props: {
    theme?: "dark" | "light";
    tip?: string;
}) {

    return (
        <div className={`w-screen h-screen flex center  relative ${props.theme}`}>
            <div className={`loading-container`}>
                <div className="loading-flipper"></div>
            </div>
            <div className='color-blue-500 absolute ' style={{top: 'calc(50vh + 50px)'}}>
                <span>{props.tip || '正在加载...'}</span>
            </div>
        </div>
    )

}
