import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { create } from 'zustand';

import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en';
import 'dayjs/locale/vi';
import dayjs from 'dayjs';

import en from './en-US.json';
import zh from './zh-CN.json';
import vi from './vi-VN.json';

const localLocale = localStorage.getItem("SK_LOCALE") || 'zh-CN';

export const localeMap = {
    'zh-CN': '中文',
    'en-US': 'English',
    'vi-VN': 'Tiếng Việt'
}

export const localeOptions = [
    { label: "中文", value: "zh-CN" },
    { label: "English", value: "en-US" },
    { label: "Tiếng Việt", value: "vi-VN" },
];


if (localLocale === 'zh-CN') {
    dayjs.locale('zh-cn');
} else if (localLocale === 'en-US') {
    dayjs.locale('en');
} else if (localLocale === 'vi-VN') {
    dayjs.locale('vi');
}


interface LocaleStore {
    locale: string,
    localeLabel: string,
    setLocale: (locale: string) => void
}

export const useLocaleStore = create<LocaleStore>((set) => ({
    locale: localLocale,
    localeLabel: localeMap[localLocale],
    setLocale: (locale: string) => {
        i18n.changeLanguage(locale);
        set({
            locale,
            localeLabel: localeMap[locale],
        })
        localStorage.setItem("SK_LOCALE", locale);
        if (locale === 'zh-CN') {
            dayjs.locale('zh-cn');
        } else if (locale === 'en-US') {
            dayjs.locale('en');
        } else if (locale === 'vi-VN') {
            dayjs.locale('vi');
        }
    },
}));





i18n
    .use(initReactI18next)
    .init({
        resources: {
            'en-US': { translation: en },
            'zh-CN': { translation: zh },
            'vi-VN': { translation: vi }
        },
        lng: localLocale,
        fallbackLng: localLocale,
        interpolation: {
            escapeValue: false
        }
    });